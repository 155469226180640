<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card-placeholder">
          <vb-headers-tag :data="{ title: 'Gérer les Bibliothhèques' }" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card-top card-top-danger">
          <div class="card-header py-0">
            <vb-headers-card-header-tabbed :data="{ title: 'Liste des Bibliothhèques' }" />
          </div>
          <div class="card-body">
             <vb-controls-button-2
              to="/ajouterbib"
              :data="{ title: 'Ajouter Bibliothhèques', icon: 'fe fe-plus-circle', type: 'primary' }"
            />
          </div>
            <div class="card-body"><library-table /></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VbHeadersTag from '@/components/widgets/Headers/Tag'
import VbControlsButton2 from '@/components/widgets/Controls/Button2'
import VbHeadersCardHeaderTabbed from '@/components/widgets/Headers/CardHeader4'
import libraryTable from '@/components/widgets/TablesAntd/library'

export default {
  name: 'VbPublications',
  components: {
    VbHeadersTag,
    VbHeadersCardHeaderTabbed,
    VbControlsButton2,
    libraryTable,
  },
}
</script>
