<template>
  <div class="table-responsive text-nowrap">
    <a-table :columns="columns" :data-source="data" :row-key="_id" :loading="tableLoading">
      <template
        #filterDropdown="{
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          column,
        }"
      >
        <div class="custom-filter-dropdown">
          <a-input
            :placeholder="`Search ${column.dataIndex}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block"
            @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
            @pressEnter="() => handleSearch(selectedKeys, confirm)"
          />
          <a-button
            type="primary"
            size="small"
            style="width: 90px; margin-right: 8px"
            @click="() => handleSearch(selectedKeys, confirm)"
            >Search</a-button
          >
          <a-button size="small" style="width: 90px" @click="() => handleReset(clearFilters)"
            >Reset</a-button
          >
        </div>
      </template>
      <template #language>
        <a-button @click="changeLanguage">
          <i class="fe fe-globe"></i>
          {{language.key==0?"Français":(language.key==1?"العربية":"English")}}
        </a-button>
      </template>
      <template #filterIcon="{ text: filtered }">
        <a-icon type="search" :style="{ color: filtered ? '#108ee9' : undefined }" />
      </template>
      <template #createdAt={text}>
        {{moment(text).lang("fr").format('DD-MM-YYYY')}}
      </template>
    <template #expandedRowRender="{ record }">
      <div class="row">
        <div class="col-md-12">
          <div v-html="record.desc?record.desc[language.value[language.key]]:''"></div>          
        </div>
      </div>
    </template>      
      <template #title="{ text }">
        {{ text?text[language.value[language.key]]:""}}
      </template>
      <template #seen="{ text }">
        <span>{{ text }}</span>
      </template>
      <template #status="{ text }">
        <span
          :class="[
            text === 'inactive'
              ? 'font-size-12 badge badge-primary'
              : 'font-size-12 badge badge-success',
          ]"
          >{{ text === 'inactive'?'Inactive' :"Active"}}</span
        >
      </template>
      <template #action={record}>
        <span>
          <a class="btn btn-sm btn-light mr-2" @click="showPreview(record)">
            <i class="fe fe-eye mr-2" />
            Visualisé
          </a>
          <a class="btn btn-sm btn-light mr-2" @click="edit(record)">
            <i class="fe fe-edit mr-2" />
            Éditer
          </a>         
          <a class="btn btn-sm btn-light" @click="suppPub(record)">
            <small>
              <i class="fe fe-trash mr-2" />
            </small>
            {{record.status=='active'?'Désactiver':"Activer"}}
          </a>         
        </span>
      </template>
    </a-table>
    <a-modal
      v-model:visible="visible"
      :width="300"
      :title="null"
    >
      <Preview-publications
      :data="modelInfo.data"
      :active-key="modelInfo.activeKey"
      />
    </a-modal>
    <a-modal
      v-model:visible="visbleCreationDate"
      :width="300"
      title="Date to publication"
      @ok="handleOk"
    >
    <p>Moifier la date de creation avant de publier</p>
     <a-date-picker show-time placeholder="Select Time" :disabled-date="disabledDate" @change="onChange" @ok="onOk" />
    </a-modal>
  </div>
</template>

<script>
/* eslint-disable */
import moment from "moment";
import ApiClient from "@/services/axios"
import PreviewPublications from "@/components/PreviewPublications"
import { message } from 'ant-design-vue';
export default {
  components:{
    PreviewPublications
  },
  created() {
      this.tableLoading=true
    ApiClient.post('/bibliographies/filter',{
      query:{}
    }).then((res)=>{
      this.data = res.data
    }).finally(()=>{
      this.tableLoading=false
    })
  },
  data(){
    return {
      tableLoading:false,
      language:{
        value:["fr","ar","en"],
        key:0
      },
      visible:false,
      visbleCreationDate:false,
      modelInfo:{
        backgroundUrl:"",
        title:{en:'',fr:'',ar:''},
        files:[],
        desc:{en:'',fr:'',ar:''},
      },
      columns:[      
        {
          title: 'Langue',
          slots: {
              customRender: 'language',
          },          
        },   
        {
          title: 'ID',
          dataIndex: 'id',
          slots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'id',
          },
          sorter: (a, b) => a.id - b.id,
          onFilter: (value, record) => record.id.toLowerCase().includes(value.toLowerCase()),
        },            
        {
          title: 'Titre',
          dataIndex: 'title',
          slots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'title',
          },
          sorter: (a, b) => a.title - b.title,
          onFilter: (value, record) => record.title.toLowerCase().includes(value.toLowerCase()),
        },  
        {
          title: 'vu',
          dataIndex: 'seen',
          sorter: (a, b) => a.seen - b.seen,
          slots: { customRender: 'seen' },
        },             
        {
          title: 'Date de publication',
          dataIndex: 'createdAt',
          slots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'createdAt',
          },          
        },
        {
          title: 'Status',
          dataIndex: 'status',
          sorter: (a, b) => a.status.length - b.status.length,
          slots: { customRender: 'status' },
        },
        {
          title: 'Action',
          slots: { customRender: 'action' },
        },
      ],
      searchText:'',
      searchInput:null,
      data:[],
      creationDate:null,
      activeRecord:null,
    }
  },
  methods: {
    moment,
    changeLanguage(){
      this.language.key = (this.language.key+1)%3
    },
    edit(record){
      this.$router.push({name: 'editerbib', params: { id: record._id}});
    },
    suppPub(record){
      const updateData = {status:record.status=='active'?"inactive":'active'}
      if(record.status=='inactive')
      {
        this.activeRecord=record;
        this.visbleCreationDate=true;
      }
      else{
         ApiClient.patch('/bibliographies/'+record._id,{
        data:updateData
      }).then(()=>{
        this.data = [...this.data.map((elem)=>elem._id == record._id?{...elem,status:record.status=='active'?"inactive":'active'}:elem)]
        message.success(`La publication est ${record.status=='active'?"désactivé":'activé'}`)
      })
      .catch(()=>{
        message.warning("Impossible d'activer/désactiver la bibliothèque")
      }) 
      }
         
      },
    showPreview(record){
      this.modelInfo = {
        data:record,
        activeKey:this.activeKey
      }
      this.visible = true
    },
    handleReset(){
      clearFilters(clearFilters)
      searchText.value = ''
    },
    handleSearch (selectedKeys, confirm){
      confirm()
      searchText.value = selectedKeys[0]
    },
     onChange(value, dateString) {
      console.log('Selected Time: ', value);
      this.creationDate=value
      console.log('Formatted Selected Time: ', dateString);
    },
      handleOk(e){
        ApiClient.patch('/bibliographies/'+this.activeRecord._id,{
        data:{status:'active',createdAt: this.creationDate}
      }).then(()=>{
        this.data = [...this.data.map((elem)=>elem._id == this.activeRecord._id?{...elem,status:this.activeRecord.status=='active'?"inactive":'active',createdAt:this.creationDate}:elem)]
        message.success(`La publication est ${this.activeRecord.status=='active'?"désactivé":'activé'}`)
      })
      .catch(()=>{
        message.warning("Impossible d'activer/désactiver la bibliothèque")
      }) 
      this.visbleCreationDate = false;
    },
        disabledDate(current){
      // Can not select days before today and today
      return  current > moment().endOf('day');
    }
  },
}
</script>

<style scoped>
.custom-filter-dropdown {
  padding: 8px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.highlight {
  background-color: rgb(255, 192, 105);
  padding: 0px;
}
</style>
